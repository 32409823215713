import Link from "next/link"
import { HeaderShim } from "../fuselage/global/header/header"
import { FooterShim } from '../fuselage/global/footer/footer'
 
export default function FourZeroFour () {
    return (
        <>
            <HeaderShim/>
            <section className="block">
                <div className="container">
                    <p className="fs-2 fw-600 c-primary">Page not found!</p>
                    <p>Please check your URL or go to the <Link href='/'><a>homepage</a></Link>.</p>
                </div>
            </section>
        </>
    )
}